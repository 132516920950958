import { Component, OnDestroy, OnInit} from '@angular/core';
import {MenuController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Subscription } from "rxjs";


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription;

  public appPages = [
    { title: 'SETTINGS.STARTWINDOW', url: '/home', icon: 'home'},
    { title: 'WIND.TITLE', url: '/wind', src:"/assets/icon/wind-sock.svg"},
    { title: 'WIND.WINDTRIANGLE', url: '/windtriangle', src:"/assets/icon/windtriangle.svg" },
    { title: 'METAR.TITLE', url: '/metar', icon: 'partly-sunny' },
    { title: 'WEATHER.TITLE', url: '/weather', icon: 'cloud' }
  ];

  public appPages2 = [
    { title: 'ABOUT.TITLE', url: '/about', icon: 'help' },
    { title: 'SETTINGS.TITLE', url: '/settings', icon: 'build' },
  ];

  constructor(
    private menuController: MenuController,
    private translate: TranslateService
  ) {
      
    } 
  
  ngOnInit() {
    this.translate.addLangs(['en', 'de']);
    this.translate.setDefaultLang('en');

  }  



  async closeMenu() {
    await this.menuController.close('mainContent');
  }

  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
