export interface IUnitClass {
  unit: string;
  value: number;
  rangefrom: number;
  rangeto: number;
  unittype: string;
}

export class UnitClass implements IUnitClass {


  public unit: string;
  public rangefrom: number;
  public rangeto: number = null;
  public value: number;
  public unittype: any;

  constructor(u: string, v: number, rf: number, rt: number = 0, unittype: string) {
    this.unit = u ? u : '';
    this.rangefrom = rf ? rf : 0;
    if (rt) { this.rangeto = rt; }
    this.value = v ? v : 0;
    this.unittype = unittype ? unittype : '-';
  }
}
