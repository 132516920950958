<ion-app >
    <ion-split-pane contentId="main-content" when="false">
        <ion-menu contentId="main-content" type="overlay" >
          <ion-content>
            <ion-list id="inbox-list" (click)="closeMenu()">
              <ion-list-header>Navigation</ion-list-header>
              <ion-note></ion-note>
              <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
                <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
                  <ion-icon *ngIf="p?.icon" aria-hidden="true" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon"></ion-icon>
                  <ion-icon *ngIf="p?.src" aria-hidden="true" slot="start" [src]="p.src"></ion-icon>
                  <ion-label>{{ p.title | translate }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-list>
    <hr>
            <ion-list id="inbox-list2" (click)="closeMenu()">
              <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages2; let i = index">
                <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
                  <ion-icon *ngIf="p?.icon" aria-hidden="true" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon"></ion-icon>
                  <ion-icon *ngIf="p?.src" aria-hidden="true" slot="start" [src]="p.src"></ion-icon>
                  <ion-label>{{ p.title | translate }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-list>
          </ion-content>
        </ion-menu>
        <ion-router-outlet id="main-content"></ion-router-outlet>
      </ion-split-pane>
</ion-app>
