export const environment = {
  production: false,
  version: '3.0.0',
  weatherApiKey: '65ecc7005e61eb8b242aa7b1dfdb05d4',
  xweatherApiUrl: 'https://api.openweathermap.org/data/2.5/onecall',
  weatherAPILocation: 'https://api.openweathermap.org/geo/1.0/direct',
  weatherApiIcon: 'https://openweathermap.org/img/wn/',
  weatherApiUrl: 'https://goaround.org/api/index.php/weather/list',
  searchAirports: 'https://goaround.org/api/index.php/airport/search',
  searchMetarStations: 'https://goaround.org/api/index.php/airport/metarstations',
  getAirport: 'https://goaround.org/api/index.php/airport/get',
  getRunways: 'https://goaround.org/api/getrunway.php',
  metarApi: 'https://goaround.org/api/index.php/metar/'
};
