
<ion-item color="transparent"  style="display:flex; align-items: center;padding-left:1rem;" [ngClass]="inputclass"  class="ion-no-padding">

  <ion-label slot="start">{{label}}&nbsp;</ion-label>
  <ion-input class="ion-text-right"  label="" color="transparent" readonly="true" *ngIf="readonly" value="{{ cvt.roundTo(value.value, roundto)  }}" type="string"></ion-input>

  <ion-input label="" style="text-align: right" *ngIf="!!!readonly" inputmode="decimal" type="number" [(ngModel)]="displayValue" type="number"
          (ngModelChange)="onValueChanged()" (ionFocus)="onFocused($event)"    (ionBlur)="onBlured($event);" min = "0">
  </ion-input>
  <ion-select  interface="popover" (ionChange)="onUnitChanged($event.detail.value)" label=""
      [selectedText]='translate.get("UNITS." + value?.unit) | async' class="units">
      <ion-select-option *ngFor="let u of units  |keyvalue" [value]="u?.key">{{("UNITS." + u?.key)|translate}}
      </ion-select-option>
    </ion-select>
</ion-item>

