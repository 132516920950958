import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';


import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import * as Hammer from 'hammerjs';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {UnitComponentModule} from './components/unitinput/unitinput.module';
import {FormsModule} from '@angular/forms';
import {InputpopupComponentModule} from './components/inputpopup/inputpopup.module';
import {ConvertService} from './services/convert.service';

import { SettingsService } from './services/settings.service';
import {IonicStorageModule} from '@ionic/storage-angular';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import {ApiService} from './services/api.service';
import {UnitPipe} from './pipes/unitpipe';
import {ZifferPipe} from './pipes/zifferpipe';
import {CommonModule} from '@angular/common';
import {DeviceOrientation} from '@awesome-cordova-plugins/device-orientation/ngx';
import {PipesModule} from "./pipes/pipes.module";
import { AppInitService } from './services/appinit.service';


export function initializeApp(appInitService: AppInitService) {
    return (): Promise<any> => { 
      return appInitService.Init();
    }
  }
 
export class MyHammerConfig extends HammerGestureConfig {
    overrides = {
        swipe: {direction: Hammer.DIRECTION_ALL},
        press: { time: 1500 }
    };
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule,
        CommonModule,
        HammerModule,
        HttpClientModule,
        InputpopupComponentModule,
        FormsModule,
        PipesModule,
        UnitComponentModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: '__mydb'
        }),
        AppRoutingModule],
    providers: [
        AppInitService,
        SettingsService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            multi: true,
            deps: [AppInitService, SettingsService]
          },
        ConvertService,
        TranslateService,
        UnitPipe,
        ZifferPipe,
        ApiService,
        Geolocation,
        DeviceOrientation,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
