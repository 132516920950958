import { Injectable }  from '@angular/core';
import { SettingsService } from './settings.service';
 
@Injectable()
export class AppInitService {
 
    constructor(private settingService: SettingsService) {
    }
    
    Init() {
 
        return new Promise<void>((resolve, reject) => {
            this.settingService.createStorage().then( (store)=> {
                this.settingService.init(store);
                resolve();
            })
 
        });
    }
}