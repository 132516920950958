import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { UnitinputComponent } from './unitinput.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [UnitinputComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    IonicModule,
  ],
    exports: [UnitinputComponent]
})
export class UnitComponentModule {}
