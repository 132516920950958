import {UnitClass} from './unit.class';

export interface IRunwayClass {
    rwydirection: UnitClass;
    rwyVector: number;
    rwyVectorTransform: string;
    rwyhi: number;
    rwylo: number;
    rwyText: string;
    rwyClass: string;
}

export class RunwayClass implements IRunwayClass {
    get rwydirection(): UnitClass {
        return this._rwydirection;
    }

    set rwydirection(value: UnitClass) {
        this._rwydirection = value;
    }

    get rwyVector(): number {
        return this._rwyVector;
    }

    set rwyVector(value: number) {
        this._rwyVector = value;
    }

    get rwyVectorTransform(): string {
        return this._rwyVectorTransform;
    }

    set rwyVectorTransform(value: string) {
        this._rwyVectorTransform = value;
    }

    get rwyhi(): number {
        return this._rwyhi;
    }

    set rwyhi(value: number) {
        this._rwyhi = value;
    }

    get rwylo(): number {
        return this._rwylo;
    }

    set rwylo(value: number) {
        this._rwylo = value;
    }

    get rwyText(): string {
        return this._rwyText;
    }

    set rwyText(value: string) {
        this._rwyText = value;
    }

  get rwyClass(): string {
    return this._rwyClass;
  }

  set rwyClass(value: string) {
    this._rwyClass = value;
  }

    private _rwydirection: UnitClass;
    private _rwyVector: number;
    private _rwyVectorTransform: string;
    private _rwyhi: number;
    private _rwylo: number;
    private _rwyText: string;
    private _rwyClass: string;

    constructor(
        rwyVector = 0,
        rwyhi = 0,
        rwylo = 90,
        rwyText = '0/90',
        rwyClass = 'crosswindok'
    ) {
        this._rwyVector = rwyVector;
        this._rwydirection = new UnitClass('degree', rwyVector, 0, 0, 'DG'),
        this._rwyhi = rwyhi;
        this._rwylo = rwylo;
        this._rwyText = rwyText;
        this._rwyClass = rwyClass;
    }
}


