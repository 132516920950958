import { Injectable } from '@angular/core';
import { UnitClass } from '../classes/unit.class';
import { TranslateService } from '@ngx-translate/core';
import { Subject, firstValueFrom } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { RunwayClass } from '../classes/runway.class';


@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    // Enthält alle Seicherelemente
    initkeys = {
        "AIRCRAFTS": [],
        "ACTIVATERUNWAY": false,
        "ALTERNATERWY": false,
        "DARKMODE": false,
        "DEFAULTAIRCRAFT": "",
        "DEFAULTDISTANCEUNIT": "km",
        "DEFAULTPRESSUREUNIT": "mbar",
        "DEFAULTRWY1": new UnitClass('degree', 0, 0, 0, 'DGO'),
        "DEFAULTRWY2": new UnitClass('degree', 90, 0, 0, 'DGO'),
        "DEFAULTRWYDISTANCEUNIT": 'm',
        "DEFAULTSTARTWINDOW": 'wind',
        "DEFAULTTEMPERATUREUNIT": 'C',
        "DEFAULTVERTICALDISTANCEUNIT": 'ft',
        "DEFAULTWINDSPEEDUNIT": 'kt',
        "ENABLECROSSWINDLIMITS": false,
        "PLACES": [],
        "RWY": [ 
            new RunwayClass(0, 18, 36, '18 / 36'),
            new RunwayClass(90, 9, 27, '09 / 27')
        ],
        "Vg": new UnitClass('kt', 5, 0, 0, 'S'),
        "WDLIMIT": new UnitClass('kt', 25, 0, 0, 'S'),
        "WINDSPEED": new UnitClass('kt', 5, 0, 0, 'S'),
        "WINDSPEETUNIT": 'kmh',
        "WINDTRIANGLE": null,
        "WINDVECTOR": 0,
        "WindDirection": new UnitClass('degree', 90, 0, 0, 'DGO'),
        "corrdistanceEntry": null,
        "currentLanguage": 'en',
        "elevation": null,
        "enroutefuel": null,
        "gradientEntry": null,
        "qnh": null,
        "startdistanceEntry": null,
        "startfuel": null,
        "temperature": null,
        "homestations":[]
    }

    private selectedLanguage = 'en';

    // tslint:disable-next-line:variable-name
    private _storage: Storage | null = null;

    private updateSetting = new Subject<string>();
    updateSetting$ = this.updateSetting.asObservable();

    dateFormat = 'dd.MM.YY HH:mm  z';
    constructor(
        public translate: TranslateService,
        public storage: Storage) {            
    }

    createStorage() {
        return this.storage.create();
    }

    init(newstorage) {
        this._storage = newstorage;
        this.initStorage();
        this.getCurrentLanguage();
    }


    public  initStorage() {
        return Object.keys(this.initkeys).forEach(async (k: string) => {
            if (k.valueOf()) {
                let v = await this._storage.get(k);
                    if (v) {
                        // console.log("Eintrag gefunden: ", k, v)
                        this.initkeys[k] = v;
                    } else {
                        this._storage.set(k, this.initkeys[k])
                        // console.log("Eintrag setzen: ", k, this.initkeys[k])
                    }
            }  
        })
    }

    async setValue(key, value) {
        if ((key?.length>0) && (value !== undefined)) {
            this.initkeys[key] = value;
            await this._storage.set(key, value);
            this.updateSetting.next(key)
        }
    }

    get(key: string) {
        try {
            return this.initkeys[key]
        } catch (e) {
            return null;
        }
    }

    getWithDefault(key: string, def) {
        const r =  this.get(key);
        if (!r) {
            return def;
        }
        return r;
    }
    getCurrentLanguage() {
        this._storage.get('currentLanguage').then(async (currentLanguage) => {
            if (currentLanguage) {
                this.translate.use(currentLanguage);
                this.selectedLanguage = currentLanguage;
            } else {
                this.setLanguageFromBrowser();
            }
            // this.dateFormat = await firstValueFrom((this.translate.getTranslation('DATEFORMAT')));
            this.dateFormat = await firstValueFrom(this.translate.get('DATEFORMAT'));
        }
        ).catch(error => {
            this.setLanguageFromBrowser();
        });
    }

    getselectedLanguage() {
        return this.selectedLanguage;
    }

    getDarkMode() {
        this._storage.get('DARKMODE').then(v => {
            // document.body.classList.toggle('dark', v);
        })
    }
    setDarkMode(v: boolean) {
        this._storage.set('DARKMODE', v).then(v => {
            // document.body.classList.toggle('dark', v);
        })
    }


    setLanguageFromBrowser() {
        const browserLang = this.translate.getBrowserLang();
        this.setCurrentLanguage(browserLang.match(/en|de/) ? browserLang : 'en');
    }

    setStartWindow(w: string) {
        this.setValue('DEFAULTSTARTWINDOW', w).then();
    }

    getStartWindow() {
        return this._storage.get("DEFAULTSTARTWINDOW");
    }

    async setCurrentLanguage(currentLanguage) {
        this.selectedLanguage = currentLanguage;
        await this.setValue('currentLanguage', currentLanguage);
        this.translate.use(currentLanguage);
    }


    // set a key/value object
    async setObject(key: string, aobject: object) {
        try {
            const result = await this._storage.set(key, JSON.stringify(aobject));
            return true;
        } catch (reason) {
            // console.log(reason);
            return false;
        }
    }
    // get a key/value object
    async getObject(skey: string): Promise<any> {
        try {
            const result = await this._storage.get(skey);
            if (result != null) {
                return JSON.parse(result);
            }
            return null;
        } catch (reason) {
            // console.log(reason);
            return null;
        }
    }

    async remove(skey: string) {
        await this._storage.remove(skey);
    }

    getDefaultValue(key: string) {
        try {
            let v = this.get(key);
            if (v) {
                return v;
            } else {
                return this.translate.instant(key);
            }
        } catch (e)  {
            return this.translate.instant(key);
        };
    }


    // Behandlung der Favoriten
    getFavorites(key: string) {
        const f = this.get(key);
        if (Array.isArray(f)) {
            return f;
        } else {
            return [];
        }
    }

    checkFavorites<bool>(key: string, value: string) {
        const f = this.getFavorites(key);
        return f.indexOf(value);
    }

    async setFavorite(key: string, value: string) {
        const f = this.getFavorites(key);
        for (const i of f) {
            const index = f.indexOf(i);
            if (i === value) {
                f.splice(index, 1);
                await this.setValue(key, value);
                return;
            }
        }
        f.push(value);
        await this.setValue(key, value);
    }
}

