import {HttpClient, HttpParams} from '@angular/common/http';
import {AirportObject} from '../classes/airport.class';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {SearchObject} from '../weather/weather.page';
import {CountryObject, OpenWeatherObject} from '../classes/openweather.class';
import {RunwaysDataObj} from '../classes/runwaysData';
import { Stations } from '../home/home.page';

export interface LatLon  {
    latitude: number;
    longitude: number;
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private http: HttpClient) {}


    async searchAirportFromId(id) {
        if (id) {
            let queryParams = new HttpParams();
            queryParams = queryParams.append('icao', id);
            return await firstValueFrom(
                this.http.get<AirportObject[]>(environment.getAirport,
                    {params: queryParams}));
        } else {
            return null;
        }
    }

    async searchAirportFromIdent(ident) {
        if (ident) {
            let queryParams = new HttpParams();
            queryParams = queryParams.append('icao', ident);
            return await firstValueFrom(
                this.http.get<AirportObject[]>(environment.getAirport,
                    {params: queryParams}));
        } else {
            return null;
        }
    }

    async searchAirportFromName( name: string) {
        if (name && name.length > 1) {
            let queryParams = new HttpParams();
            queryParams = queryParams.append('q', name);
            return await firstValueFrom( this.http.get<SearchObject[]>(environment.searchAirports + 'airports.php',
                {params: queryParams}));
        } else {
            return null;
        }
    }

    async searchAirportFromNameNew( name: string) {
        if (name && name.length > 1) {
            let queryParams = new HttpParams();
            queryParams = queryParams.append('q', name);
            return await firstValueFrom( this.http.get<SearchObject[]>(environment.searchAirports,
                {params: queryParams}));
        } else {
            return null;
        }
    }

    async searchMetarstationFromName( name: string) {
        if (name && name.length > 1) {
            let queryParams = new HttpParams();
            queryParams = queryParams.append('q', name);
            return await firstValueFrom( this.http.get<Stations[]>(environment.searchMetarStations,
                {params: queryParams}));
        } else {
            return null;
        }
    }

    searchRunwaysIdent(ident) {
        if (ident) {
            let queryParams = new HttpParams();
            queryParams = queryParams.append('ident', ident);
            return this.http.get<RunwaysDataObj[]>(environment.getRunways,
                {params: queryParams});
        } else {
            return null;
        }
    }

    /**
     * Ermittelt die Wetterdaten in Abhängigkeit vom Namen oder der Koordinate
     * @param town LatLon
     * @param gps GPS KOORDINATE
     * @param language  Sprache
     */
    async getOpenWeather(position: LatLon,  language = 'en'): Promise<OpenWeatherObject> {
        let queryParams = new HttpParams();
        if (position?.latitude && position?.longitude) {
            queryParams = queryParams.append('lat', position.latitude);
            queryParams = queryParams.append('lon', position.longitude);
            queryParams = queryParams.append('lang', language);
            queryParams = queryParams.append('exclude', 'hourly,minutely');
            return await firstValueFrom(this.http.get <OpenWeatherObject>(environment.weatherApiUrl, {params: queryParams}));
        }
        return null;
    }

    /**
     * ermittelt die Koordinaten einer Stadt
     * @param town  Name der Stadt
     * @param language  Sprache Default = en
     */
    async selectTown(town: string, language = 'en'): Promise<LatLon> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('q', town);
        queryParams = queryParams.append('APPID', environment.weatherApiKey);
        queryParams = queryParams.append('lang', language);
        const coord = await firstValueFrom( this.http.get<CountryObject>(environment.weatherAPILocation, {params: queryParams}));
        return {latitude: coord[0].lat, longitude: coord[0].lon};
    }

    unix2Date(unixTimestamp: number) {
        const options = {dateStyle: 'short', timeStyle: 'long'};
        if (unixTimestamp) {
            // @ts-ignore
            return new Intl.DateTimeFormat('en-US', options).format(new Date(unixTimestamp * 1000));
        } else {
            return null;
        }
    }
}
