  
  <app-unitinput *ngIf="inpType!=='string'" label="{{inplabel}}" [(ngModel)]="inpvalue"  [(units)]="cvt.DEGREECONV"></app-unitinput>
  <ion-item  *ngIf="inpType==='string'">
    <ion-label>{{inplabel}}</ion-label>
    <ion-input [(ngModel)]="inpvalue" ></ion-input>
  </ion-item>
  <ion-item> 
      <ion-button  pullright (click)="cancel()">{{'CANCEL' | translate}}</ion-button>
      <ion-button pullright (click)="selectValue(inpvalue)">{{ 'OK' | translate}}</ion-button>  
  </ion-item>

